.admin_content .content {
  max-width: 100%;
}

.table_header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.table_hader .title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_header h1 {
  margin: 0;
}

.table_header .search_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_header .search_div .select_search_by {
  margin-right: 10px;
}

.export_button {
  background-color: transparent;
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 18px;
  display: flex;
  height: auto;
  color: #f39325;
  font-family: "Roboto";
  font-weight: 500;
  border: 1px solid #f39325;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  margin-right: 10px;
}

.export_button:hover {
  background-color: #f39325;
  color: #fff;
  border: 2px solid #f39325;
}

.dash_card {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
}

.dash_card .dash_title {
  font-size: 14px;
  margin: 0;
}

.dash_card_content {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash_card .dash_card_content .anticon {
  font-size: 40px;
}

.dash_card .dash_card_content .number p {
  font-size: 40px;
  margin: 0;
  font-weight: bold;
}
