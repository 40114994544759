.register {
  background-image: url("../../../assets/images/bg-grey-neuronios.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.title_page {
  font-size: 35px;
  color: #f39325;
  text-align: center;
}

.ant-upload {
  width: 100%;
}

.input_upload {
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 0 !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.input_upload:hover {
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 0 !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.input_upload_name {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  color: #702283;
}

.input_upload_button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-width: 200px;
  max-width: 200px;
  padding: 10px 20px;
  background-color: #f39325;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.input_upload_button:hover {
  background-color: #702283;
}

.form_register {
  margin-bottom: 40px;
}

.form_register input {
  border: 2px solid #702283;
  height: 50px;
}

.form_register input:hover {
  border-right: 2px solid #f39325 !important;
}

.form_register input::placeholder {
  color: rgba(112, 34, 131, 0.5);
  font-weight: bold;
}

.form_register a {
  color: #f39325;
}

.form_register .info_form {
  color: #193a4d;
}

.form_register .primary_button {
  margin: auto;
}

.form_register .primary_button:hover {
  background-color: #702283;
  border: 2px solid #702283;
  color: #fff;
}

.file_row {
  width: 100%;
  flex-wrap: nowrap;
}

.upload_file {
  position: absolute;
  right: 14px;
  top: 2px;
}

.upload_file_name {
  width: 100%;
}

.upload_file_name input {
  border: 0;
  border: 2px solid #702283;
  border-radius: 8px;
  padding-right: 220px !important;
}

.ant-upload-list.ant-upload-list-text {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .input_upload_button {
    width: 100%;
    max-width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: 2px solid #702283;
    border-left: 2px solid #702283;
    border-bottom: 2px solid #702283;
  }

  .upload_file {
    position: initial;
    right: 0;
    top: 48px;
    width: 100%;
  }

  .file_row {
    flex-wrap: wrap;
  }

  .upload_file_name {
    width: 100%;
    margin-bottom: 0 !important;
  }

  .upload_file_name input {
    padding-right: 11px !important;
    border: 0;
    border: 2px solid #702283;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
