.privacy_page {
  background-color: #FFF;
  padding: 40px 20px;
}

.privacy_page .title_page {
  font-size: 35px;
  color: #F39325;
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.privacy_page .title_privacy {
  font-size: 25px;
  color: #AEBA00;
  font-weight: bold;
  margin-top: 50px;
}

.privacy_page ul {
  padding-inline-start: 25px;
}

.privacy_page ul li {
  font-size: 20px;
  color: #193A4D;
  margin-bottom: 10px
}

.privacy_page ol {
  padding-inline-start: 25px;
}

.privacy_page ol {
  counter-reset: list;
  margin: 0;
}

.privacy_page ol>li {
  list-style: none;
  position: relative;
}

.privacy_page ol>li:before {
  counter-increment: list;
  content: counter(list, lower-alpha) ") ";
  position: absolute;
  left: -1.4em;
  color: #AEBA00;
  font-weight: bold;
}

.regulation_page ol li {
  font-size: 20px;
  color: #193A4D;
  margin-bottom: 10px
}

.privacy_page p {
  font-size: 20px;
  color: #193A4D;
}

.privacy_page a {
  color: #F39325;
}