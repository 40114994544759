.cookies_page {
  background-color: #fff;
  padding: 40px 20px;
}

.cookies_page .title_page {
  font-size: 35px;
  color: #f39325;
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.cookies_page .title_cookies {
  font-size: 25px;
  color: #aeba00;
  font-weight: bold;
  margin-top: 50px;
}

.cookies_page ul {
  padding-inline-start: 25px;
}

.cookies_page ul li {
  font-size: 20px;
  color: #193a4d;
  margin-bottom: 10px;
}

.cookies_page ol {
  padding-inline-start: 25px;
}

.cookies_page ol {
  counter-reset: list;
  margin: 0;
}

.cookies_page ol > li {
  list-style: none;
  position: relative;
}

.cookies_page ol > li:before {
  counter-increment: list;
  content: counter(list, lower-alpha) ") ";
  position: absolute;
  left: -1.4em;
  color: #aeba00;
  font-weight: bold;
}

.cookies_page ol li {
  font-size: 20px;
  color: #193a4d;
  margin-bottom: 10px;
}

.cookies_page p {
  font-size: 20px;
  color: #193a4d;
}

.cookies_page a {
  color: #f39325;
}
