.banner_home {
  background: linear-gradient(90deg, #28347f 40%, transparent 60%) 0% 0% no-repeat padding-box, url("../../assets/images/Banner.jpg");
  background-size: 100%, cover;
  background-position: center, right center;
  background-repeat: no-repeat, no-repeat;
  height: 700px;
  padding: 40px;
}

.banner_home .logo_banner {
  max-width: 300px;
}

.banner_home .banner_buttons {
  display: inline-flex;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.banner_home .text {
  font-size: 22px;
  color: #fff;
  font-family: "Roboto";
}

@media only screen and (max-width: 1024px) {
  .banner_home {
    background: linear-gradient(360deg, #28347f 60%, transparent 100%) 0% 0% no-repeat padding-box, url("../../assets/images/Banner-mobile.png");
    background-size: 100%, 180%;
    background-position: center, top center;
    background-repeat: no-repeat;
    height: auto;
    padding: 40px;
  }

  .banner_home .logo_banner {
    max-width: 300px;
    padding-top: 100px;
    width: 100%;
  }
}

@media only screen and (min-width: 450px) {
  .banner_home .primary_button {
    margin-right: 10px;
  }
}
