.login {
  background-color: rgba(112, 34, 131, 0.2);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_page {
  font-size: 35px;
  color: #f39325;
  text-align: center;
}

.form_login {
  width: 100%;
}

.form_login input {
  border: 2px solid #702283;
  height: 50px;
}

.form_login input::placeholder {
  color: rgba(112, 34, 131, 0.5);
  font-weight: bold;
}

.form_login .primary_button {
  margin: auto;
}

.form_login .primary_button:hover {
  background-color: #702283;
  border: 2px solid #702283;
  color: #fff;
}

.login-div {
  border-radius: 10px;
  background-color: #fff;
  height: 600px;
}

.login-div .img_login {
  background: linear-gradient(180deg, #28347f 100%, transparent 100%) 0% 0% no-repeat padding-box, url("../../../assets/images/Banner.jpg");
  background-size: 100%, cover;
  background-position: center, right center;
  background-repeat: no-repeat, no-repeat;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-div .form_login_div {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
