.admin_layout {
    height: calc(100vh);
}

.admin_layout .admin_layout_content .admin_content {
    background-color: #f8f7f7
}

.admin_layout .logo {
    height: 50px;
    padding: 20px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/logo-header.svg');
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    margin: auto;
    margin: 10px auto 10px auto;
}

.ant-layout-sider-collapsed .logo {
    height: 50px;
    padding: 20px;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/logo-header-mobile.svg');
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    margin: 10px auto 10px auto;
} 

.admin_layout_content {
    padding: 40px;
    background-color: #f8f7f7
}