.loading_page {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  pointer-events: none;
  opacity: 1;
  position: absolute;
  top: 0;
  background-color: #fff;
}

.loading_page svg {
  max-width: 300px;
}

.loading_page.hidden {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.loading_app {
  max-height: 100vh;
}

.principal_header {
  background-color: #f39325 !important;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 80px;
}

.principal_header .content_header {
  max-width: 1400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.principal_header .content_header .logo img {
  max-width: 400px;
  width: 100%;
}

.principal_header ul {
  background-color: #f39325 !important;
  font-family: "Roboto" !important;
  font-weight: bold;
  font-size: 20px;
  width: 80%;
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.principal_header ul li {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.principal_header .logo {
  height: 50px;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/logo-header.svg");
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.ant-menu-item a.active {
  color: #fff !important;
}

.principal_footer {
  padding: 0 !important;
}

.principal_footer .first_row {
  background-color: #aeba00;
  padding: 30px 20px;
}

.principal_footer .first_row .first_row_content {
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.principal_footer .first_row .first_row_content img {
  max-width: 130px;
}

.principal_footer .second_row {
  background-color: #000000;
  padding: 10px;
}

.principal_footer .second_row .second_row_content {
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.principal_footer .second_row .second_row_content p {
  font-family: "Roboto";
  font-size: 16px;
  color: #fff;
}

.principal_footer .second_row .second_row_content p a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.principal_footer .second_row .second_row_content p span {
  margin: 0 15px;
}

.inscricao_menu_button a {
  padding: 10px;
  background-color: #702283;
  border-radius: 5px;
  color: #fff;
}

.inscricao_menu_button a {
  padding: 10px 20px;
  background-color: #702283;
  border-radius: 5px;
  color: #fff !important;
}

.inscricao_menu_button:hover a {
  background-color: #ffffff;
  color: #702283 !important;
}

.menu_mobile {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .menu_desktop {
    display: none !important;
  }

  .menu_mobile {
    display: flex;
    font-size: 20px;
    color: #fff;
  }
}

@media only screen and (max-width: 575px) {
  .principal_header .logo {
    height: 50px;
    width: 100%;
    max-width: 100px;
    background-image: url("../../assets/images/logo-header-mobile.svg");
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 750px) {
  .principal_footer .second_row .second_row_content {
    justify-content: center;
  }
}
