.admin_content .content {
  max-width: 100%;
}

.dash_card {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
}

.add_user_button {
  background-color: transparent;
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 18px;
  display: flex;
  height: auto;
  color: #f39325;
  font-family: "Roboto";
  font-weight: 500;
  border: 1px solid #f39325;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  margin-right: 10px;
}

.add_user_button:hover {
  background-color: #f39325;
  color: #fff !important;
  border: 1px solid #f39325;
}
