.regulation_page {
  background-image: url("../../../assets/images/bg-grey-neuronios.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 40px 20px;
}

.regulation_page .title_page {
  font-size: 35px;
  color: #f39325;
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.regulation_page .title_regulation {
  font-size: 30px;
  color: #aeba00;
  font-weight: bold;
  margin-top: 50px;
}

.regulation_page ul {
  padding-inline-start: 25px;
}

.regulation_page ul li {
  font-size: 20px;
  color: #193a4d;
  margin-bottom: 10px;
}

.regulation_page ol {
  padding-inline-start: 25px;
}

.regulation_page ol {
  counter-reset: list;
  margin: 0;
}

.regulation_page ol > li {
  list-style: none;
  position: relative;
}

.regulation_page ol > li:before {
  counter-increment: list;
  content: counter(list, lower-alpha) ") ";
  position: absolute;
  left: -1.4em;
  color: #aeba00;
  font-weight: bold;
}

.regulation_page ol li {
  font-size: 20px;
  color: #193a4d;
  margin-bottom: 10px;
}

.regulation_page p {
  font-size: 20px;
  color: #193a4d;
}

.primary_button_regulation {
  background-color: #702283;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 20px;
  display: flex;
  height: auto;
  color: #fff;
  font-family: "Roboto";
  font-weight: bold;
  border: 2px solid #702283;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary_button_regulation:hover {
  background-color: #fff;
  border: 2px solid #702283 !important;
  color: #702283 !important;
}

.second_button_regulation {
  background-color: #f39325;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 20px;
  display: flex;
  height: auto;
  color: #fff;
  font-family: "Roboto";
  font-weight: bold;
  border: 2px solid #f39325;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second_button_regulation:hover {
  background-color: #702283;
  border: 2px solid #702283 !important;
  color: #fff !important;
}

.button_regulation_div {
  margin-top: 30px;
}

.button_regulation_div .left {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button_regulation_div .right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .button_regulation_div .left {
    justify-content: center;
  }

  .button_regulation_div .right {
    justify-content: center;
  }
}
