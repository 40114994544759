@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

body {
  font-family: "Roboto" !important;
  background-color: #ffffff;
}

/**
 * Smooth scrolling inside an element
 */
.App {
  scroll-behavior: smooth;
}

/**
* Smooth scrolling on the whole document
*/
body {
  scroll-behavior: smooth;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.ant-layout {
  background-color: #ffffff;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.orange {
  color: #f39325 !important;
}

.op-0 {
  opacity: 0;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.primary_button {
  background-color: #f39325;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 20px;
  display: flex;
  height: auto;
  color: #fff;
  font-family: "Roboto";
  font-weight: bold;
  border: 2px solid #f39325;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary_button:hover {
  background-color: #fff;
  border: 2px solid #fff !important;
  color: #f39325;
}

.second_button {
  background-color: transparent;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 20px;
  display: flex;
  height: auto;
  color: #fff;
  font-family: "Roboto";
  font-weight: bold;
  border: 2px solid #ffffff;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second_button:hover {
  background-color: #fff;
  border: 2px solid #fff !important;
  color: #702283;
}
