.mobile_drawer .ant-drawer-body {
    background-color: #F39325;
}

.mobile_drawer .ant-drawer-header {
    background-color: #F39325;
    border-bottom: 0 !important;
}

.mobile_drawer .ant-drawer-header-title {
    justify-content: flex-end;
}

.mobile_drawer .anticon.anticon-close {
    color: #FFF;
    font-size: 20px;
}

.mobile_drawer .ant-drawer-footer,
.mobile_drawer .footer_mobile {
    background-color: #AEBA00;
}

.mobile_drawer .footer_mobile {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.mobile_drawer .footer_mobile img {
    max-width: 130px;
}

.mobile_drawer .ant-menu {
    background-color: transparent !important;
    border-inline-end: 0 !important;
}

.mobile_drawer .ant-menu-item {
    background-color: transparent !important;
    font-size: 20px;
    text-align: center;
    color: #FFF;
    margin-bottom: 20px !important;
    font-weight: bold;
}

@media only screen and (min-width: 575px) {
    .ant-drawer-content-wrapper {
        width: 500px !important;
    }
}

@media only screen and (max-width: 575px) {
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}